<template>
  <div style="margin-top: 10%"></div>
  <img alt="Vue logo" src="/img/wellsum.png" style="width:300px; margin: auto;" />
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> |
    <a href="https://wellsum.web.app/login" target="_blank">Client Platform</a> |
    <a href="https://amchara.wellsum.io" target="_blank">Customer Portal</a> |
    <a href="https://admin.wellsum.io" target="_blank">Admin CRM</a>
  </div>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #EEE;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
